<template>
  <div id="fe-app-wrapper">
    <PreviewBanner v-if="isPreview" />
    <main id="main-wrapper">
      <LogoImage v-if="!embedded" />
      <Alert
        v-if="
          needType === $const.NEED_TYPE_AT_NEED &&
          !atNeedMessage &&
          $route.name !== 'Estimate'
        "
      >
        Our condolences go out to you and your family during this difficult
        time. Don't hesitate to contact us at
        <a :href="'tel:' + business.phone" id="alert-at-need-phone-link">{{
          business.phone
        }}</a>
        for help in planning arrangements.
      </Alert>
      <Alert
        v-else-if="
          needType === $const.NEED_TYPE_AT_NEED &&
          atNeedMessage &&
          $route.name !== 'Estimate'
        "
        v-html="atNeedMessage"
      ></Alert>
      <transition name="fade" mode="out-in">
        <router-view :key="$route.fullPath" />
      </transition>
      <Translate />
    </main>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LogoImage from "./components/LogoImage";
import Alert from "./components/Alert";
import PreviewBanner from "./components/PreviewBanner";
import Translate from "./components/Translate";

export default {
  name: "FuneralEstimator",
  components: { LogoImage, Alert, PreviewBanner, Translate },
  props: {
    apiBaseUrl: {
      type: String,
      required: true,
    },
    // Estimator ID - The Estimator's UUID
    id: {
      type: String,
      required: true,
    },
    // Determines if the estimator should show the business logo
    embedded: {
      type: Boolean,
      required: false,
      default: false,
    },
    // Determines if the estimator should be in preview mode
    preview: {
      type: Boolean,
      required: false,
      default: false,
    },
    // States which estimator schema version to use
    version: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapGetters(["isPreview", "needType", "business", "atNeedMessage"]),
  },
  created() {
    this.$store.dispatch("INIT", this.$props);
  },
};
</script>

<style>
#fe-app-wrapper * {
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 1rem !important;
  line-height: 1.5;
}

#fe-app-wrapper #main-wrapper #alert-at-need-phone-link {
  @apply text-blue-600 text-lg md:text-2xl hover:underline;
  font-weight: 800;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
