<template>
  <transition name="fe-modal">
    <div class="fe-modal-mask">
      <div class="fe-modal-wrapper">
        <div class="fe-modal-container p-4 sm:w-3/4 lg:w-1/2 xl:1/3">
          <div class="fe-modal-body">
            <div
              class="flex flex-col items-center py-4"
              v-if="captured"
              id="success-message"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
                class="feather feather-check-circle w-10 h-10 flex-no-shrink text-green-400"
              >
                <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
                <path d="M22 4L12 14.01l-3-3" />
              </svg>
              <span> Your Arrangement has been saved! </span>
            </div>
            <LeadForm
              :skippable="true"
              v-on:close="$emit('close')"
              v-on:captured="captured = true"
              v-else
            >
              <template v-slot:header>
                Fill out the form below to save your arrangement.
              </template>
              <template v-slot:btn-text> Save Now </template>
            </LeadForm>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import LeadForm from "./LeadForm.vue";

export default {
  name: "LeadModal",
  components: { LeadForm },
  data() {
    return {
      captured: false,
    };
  },
};
</script>

<style scoped>
#success-message > span {
  margin-top: 15px;
  text-align: center;
  font-size: 1.5rem !important;
  font-weight: bold;
}

.fe-modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.fe-modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.fe-modal-container {
  margin: 0px auto;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.skip-button {
  @apply w-full mt-4 rounded font-bold px-8 py-4 text-white text-center bg-gray-600 hover:bg-gray-500 flex justify-center items-center;
  font-size: 100%;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.fe-modal-enter {
  opacity: 0;
}

.fe-modal-leave-active {
  opacity: 0;
}

.fe-modal-enter .fe-modal-container,
.fe-modal-leave-active .fe-modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
