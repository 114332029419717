/**
 * Called when the user lands on the `/review` step
 *
 * @param {Array} selections - An array of page objects with a selections key with a type of array
 * @param {Number} estimatedTotal - Client side calculated estimated total of the arrangement.
 */
export default function (selections, estimatedTotal) {
  if (!selections || !estimatedTotal) {
    console.error(
      "FE Analytics: Review - Selections array and the Estimated Total are required."
    );
    return;
  }

  // https://developers.facebook.com/docs/facebook-pixel/reference#standard-events
  let fb = [
    "trackSingle",
    "InitiateCheckout",
    {
      content_ids: selections.map(function (page) {
        return page.selections[0].id;
      }),
      contents: selections.map(function (page) {
        return {
          id: page.selections[0].id,
          quantity: 1,
        };
      }),
      currency: "USD",
      // add two additional for NeedType & Disposition
      num_items: selections.length + 2,
      value: estimatedTotal,
    },
  ];

  if (this.shouldLog) {
    console.debug("--- Facebook Pixel (Not including Pixel ID) ---");
    console.debug(fb);
    console.debug("----");
  }

  if (this.sendToFb && fb.length > 0) {
    window.fbq(fb[0], this.pixel, fb.slice(1));
  }
}
