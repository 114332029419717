<template>
  <div id="fe-header" class="mb-4">
    <div class="w-full md:flex md:items-center md:justify-between py-2 mb-2">
      <div class="flex-1 min-w-0">
        <h1 id="fe-header-title">
          {{ title }}
        </h1>
      </div>
    </div>
    <div
      class="h2 leading-tight md:leading-none"
      v-if="description"
      v-html="description"
    ></div>
  </div>
</template>

<script>
export default {
  name: "PageHeader",
  props: {
    title: String,
    description: String,
  },
};
</script>

<style scoped>
#fe-header #fe-header-title {
  font-weight: 800;
  font-size: 1.875rem !important;
  margin: 0;
}
#fe-header * {
  color: rgb(51, 51, 51);
}
</style>
