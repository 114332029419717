var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"fe-app-wrapper"}},[(_vm.isPreview)?_c('PreviewBanner'):_vm._e(),_c('main',{attrs:{"id":"main-wrapper"}},[(!_vm.embedded)?_c('LogoImage'):_vm._e(),(
        _vm.needType === _vm.$const.NEED_TYPE_AT_NEED &&
        !_vm.atNeedMessage &&
        _vm.$route.name !== 'Estimate'
      )?_c('Alert',[_vm._v(" Our condolences go out to you and your family during this difficult time. Don't hesitate to contact us at "),_c('a',{attrs:{"href":'tel:' + _vm.business.phone,"id":"alert-at-need-phone-link"}},[_vm._v(_vm._s(_vm.business.phone))]),_vm._v(" for help in planning arrangements. ")]):(
        _vm.needType === _vm.$const.NEED_TYPE_AT_NEED &&
        _vm.atNeedMessage &&
        _vm.$route.name !== 'Estimate'
      )?_c('Alert',{domProps:{"innerHTML":_vm._s(_vm.atNeedMessage)}}):_vm._e(),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view',{key:_vm.$route.fullPath})],1),_c('Translate')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }