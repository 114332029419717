<template>
  <div id="fe-app-server-error">
    <h1>500 Internal Server Error</h1>
    <p>
      Whoops! Something went wrong. Don't worry, our developers have been
      notified!
    </p>
  </div>
</template>

<script>
export default {
  name: "InternalServerError",
};
</script>

<style scoped>
#fe-app-wrapper #main-wrapper #fe-app-server-error {
  @apply text-center mt-8 md:mt-4;
}

#fe-app-wrapper #main-wrapper #fe-app-server-error h1 {
  font-size: 2rem !important;
  font-weight: 800;
  margin-bottom: 1rem;
}

#fe-app-wrapper #main-wrapper #fe-app-server-error p {
  margin-bottom: 2rem;
  font-size: 1.25rem !important;
}
</style>
