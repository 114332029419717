import Vue from "vue";
import { constants } from "../constants";
// Analytics Event Handlers
import start from "./analytics/Start";
import navigation from "./analytics/Navigation";
import viewPage from "./analytics/ViewPage";
import selectProduct from "./analytics/SelectProduct";
import review from "./analytics/Review";
import newLead from "./analytics/NewLead";
import viewEstimate from "./analytics/ViewEstimate";
import newEstimate from "./analytics/NewEstimate";
import error from "./analytics/Error";

Vue.prototype.$const = constants;

const EventBus = new Vue({
  data() {
    return {
      // Sent and set from the Init event emitted in /store/modules/setup.js
      preview: false,
      pixel: null,
      session: null,
    };
  },
  computed: {
    shouldLog() {
      return process.env.VUE_APP_NODE_ENV === "development";
    },
    sendToFb() {
      if (
        !this.pixel ||
        process.env.VUE_APP_NODE_ENV === "development" ||
        this.preview ||
        !window.fbq
      ) {
        return false;
      }
      return true;
    },
    shouldHoard() {
      if (window.hoard && window.hoard.enabled) {
        return true;
      }
      return false;
    },
  },
});

// Analytics Specific Events
EventBus.$on("Init", function (preview, pixel, session) {
  this.preview = preview;
  this.pixel = pixel;
  this.session = session;
});
EventBus.$on("Start", start);
EventBus.$on("Navigation", navigation);
EventBus.$on("ViewPage", viewPage);
EventBus.$on("SelectProduct", selectProduct);
EventBus.$on("Review", review);
EventBus.$on("NewLead", newLead);
EventBus.$on("ViewEstimate", viewEstimate);
EventBus.$on("NewEstimate", newEstimate);
EventBus.$on("Error", error);

export default EventBus;
