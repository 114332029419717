/**
 * Handles all analytics functionality of the internal "Navigation" events.
 *
 * @param {Object} route - The current this.$route object
 *
 * Documentation:
 * https://developers.facebook.com/docs/facebook-pixel/reference#standard-events
 */

export default function (route) {
  if (!route) {
    console.error("FE Analytics: Navigation - A route object is required.");
    return;
  }

  // Facebook Pixel does not require any additional page information due
  let fb = ["trackSingle", "PageView"];

  if (this.shouldLog) {
    console.debug("--- Facebook Pixel (Not including Pixel ID) ---");
    console.debug(fb);
    console.debug("----");
  }

  if (this.sendToFb && fb.length > 0) {
    window.fbq(fb[0], this.pixel, fb.slice(1));
  }
}
