<template>
  <div class="print:hidden">
    <div id="google_translate_element" class="mt-5"></div>
  </div>
</template>

<script>
export default {
  name: "Translate",
  mounted() {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://translate.google.com/translate_a/element.js?cb=window.fe_gtranslate_callback";
    document.head.append(script);
  },
};
</script>

<style scoped>
#fe-app-wrapper #main-wrapper #google_translate_element {
  display: inline-block !important;
}
</style>
