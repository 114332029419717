import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../views/Index.vue";
import Pages from "../views/Pages.vue";
import Review from "../views/Review.vue";
import Estimate from "../views/Estimate.vue";
import NotFound from "../views/NotFound.vue";
import InternalServerError from "../views/InternalServerError.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    component: Index,
    meta: {
      requireStart: false,
    },
  },
  {
    path: "/page/:id",
    name: "Page",
    component: Pages,
    meta: {
      requireStart: true,
    },
  },
  {
    path: "/review",
    name: "Review",
    component: Review,
    meta: {
      requireStart: true,
    },
  },
  {
    path: "/estimate/:id",
    name: "Estimate",
    component: Estimate,
    meta: {
      requireStart: false,
    },
  },
  {
    path: "/404-not-found",
    name: "NotFound",
    component: NotFound,
    meta: {
      requireStart: false,
    },
  },
  {
    path: "/500-internal-server-error",
    name: "InternalServerError",
    component: InternalServerError,
    meta: {
      requireStart: false,
    },
  },
  {
    path: "*",
    redirect: {
      name: "NotFound",
    },
  },
];

const router = new VueRouter({
  mode: "hash",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requireStart && !store.state.selections.started) {
    next("/");
  } else {
    next();
  }
});

export default router;
