<template>
  <img v-if="!loading" :src="business.logo" :alt="business.name" id="fe-logo" />
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LogoImage",
  computed: {
    ...mapGetters(["loading", "business"]),
  },
};
</script>

<style scoped>
#fe-logo {
  display: block;
  margin: 0rem auto;
  text-align: center;
  max-height: 8rem;
}
</style>
