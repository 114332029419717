/**
 * Called when a user views a page's product list.
 *
 * @param {Object} page - The currentPage from whichever component is calling ViewPage
 */

export default function (page) {
  if (!page) {
    console.error("FE Analytics: ViewPage - A page object is required");
    return;
  }

  // https://developers.facebook.com/docs/facebook-pixel/reference#standard-events
  let fb = [
    "trackSingle",
    "ViewContent",
    {
      content_ids: page.products
        ? page.products.map(function (p) {
            return p.id;
          })
        : null,
      content_name: page.short_title ? page.short_title : null,
      content_type: "product",
      contents: page.products
        ? page.products.map((p) => {
            return {
              id: p.id,
              quantity: 1,
            };
          })
        : null,
      currency: "USD",
      value: 0,
    },
  ];

  if (this.shouldLog) {
    console.debug("--- Facebook Pixel (Not including Pixel ID) ---");
    console.debug(fb);
    console.debug("----");
  }
  if (this.sendToFb && fb.length > 0) {
    window.fbq(fb[0], this.pixel, fb.slice(1));
  }

  if (this.shouldHoard) {
    window.hoard.send("page_view", this.session, { pg: page.id });
  }
}
