export const constants = Object.freeze({
  DISPOSITION_TYPE_BURIAL: "BURIAL",
  DISPOSITION_TYPE_CREMATION: "CREMATION",
  DISPOSITION: {
    id: "DISPOSITION",
    short_title: "Disposition",
    title: "Disposition",
    description: "What type of service are you looking for?",
    products: [
      {
        id: "BURIAL",
        name: "Burial",
        description: null,
        image: null,
        price: {
          cents: 0,
          formatted: "$0",
        },
        show_price: false,
      },
      {
        id: "CREMATION",
        name: "Cremation",
        description: null,
        image: null,
        price: {
          cents: 0,
          formatted: "$0",
        },
        show_price: false,
      },
    ],
  },
  NEED_TYPE_AT_NEED: "AT_NEED",
  NEED_TYPE_PRE_NEED: "PRE_NEED",
  NEED: {
    id: "NEED_TYPE",
    short_title: "Need Type",
    title: "Need Type",
    description: "What best describes your current need?",
    products: [
      {
        id: "AT_NEED",
        name: "At-Need",
        description: "A death has occured or is anticipated",
        image: null,
        price: {
          cents: 0,
          formatted: "$0",
        },
        show_price: false,
        next: "disposition",
        icon: `<svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="mx-auto mb-2 h-10 w-10 block"
              >
                <path
                  d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
                ></path>
              </svg>`,
      },
      {
        id: "PRE_NEED",
        name: "Pre-Need",
        description: "I'm planning a future funeral (A death has not occured)",
        image: null,
        price: {
          cents: 0,
          formatted: "$0",
        },
        show_price: false,
        next: "disposition",
        icon: `<svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="mx-auto mb-2 h-10 w-10 block"
              >
                <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                <line x1="16" y1="2" x2="16" y2="6"></line>
                <line x1="8" y1="2" x2="8" y2="6"></line>
                <line x1="3" y1="10" x2="21" y2="10"></line>
              </svg>`,
      },
    ],
  },
});
