/**
 * Called when a user visits their estimate page
 *
 * @param {Object} route - The current this.$route object
 * @param {Number} estimatedTotal - The server returned estimated total
 */
export default function (route, estimatedTotal) {
  if (!route || !estimatedTotal) {
    console.error(
      "FE Analytics: ViewEstimate - Route object and the Estimated Total are required."
    );
    return;
  }

  let fb = [
    "trackSingleCustom",
    "ViewEstimate",
    {
      event_category: "engagement",
      event_label: route.path,
      event_value: estimatedTotal,
    },
  ];

  if (this.shouldLog) {
    console.debug("--- Facebook Pixel (Not including Pixel ID) ---");
    console.debug(fb);
    console.debug("----");
  }

  if (this.sendToFb && fb.length > 0) {
    window.fbq(fb[0], this.pixel, fb.slice(1));
  }
}
