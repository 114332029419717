<template>
  <LoadingMessage v-if="loading">
    One moment while we find your estimate
  </LoadingMessage>
  <div v-else>
    <h1
      class="my-0 text-2xl text-left font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9"
    >
      Summary of your estimated arrangement:
    </h1>
    <button :class="$style.printButton" onclick="window.print()">Print</button>
    <div>
      <div class="flex flex-col md:flex-row md:justify-between my-2">
        <h2 class="h2 capitalize my-0">
          Disposition: {{ estimate.disposition }}
        </h2>
        <h2 class="h2 capitalize my-0">Need Type: {{ estimate.need_type }}</h2>
      </div>
      <div class="w-full bg-gray-900 p-2" style="box-sizing: border-box">
        <h3 class="text-white font-bold print:text-black my-0">Selections</h3>
      </div>
      <div class="flex flex-col p-2">
        <div
          class="flex flex-row justify-between my-2"
          v-for="selection in estimate.selections"
          :key="selection.page.id"
        >
          <div>
            <span class="font-bold">{{ selection.page.name }}:</span>
            {{ selection.product.name }}
          </div>
          <div>{{ selection.product.price.formatted }}</div>
        </div>
      </div>
      <hr :class="$style.hr" />
      <div class="flex flex-row justify-between p-2">
        <h3 class="font-bold text-gray-900 my-0">Estimated Total</h3>
        <h3 class="font-bold text-gray-900 my-0">
          {{ estimate.estimated_total.formatted }}
        </h3>
      </div>
      <div
        class="bg-gray-200 rounded p-6 border-2 border-gray-500 my-4"
        v-if="estimate.resources.length !== 0"
      >
        <h3 class="text-xl font-bold text-gray-900 mb-3">
          Additional Resources
        </h3>
        <p
          v-for="resource in estimate.resources"
          v-bind:key="resource"
          v-html="resource"
        ></p>
      </div>
      <div
        class="bg-gray-200 rounded p-6 border-2 border-gray-500 my-4"
        v-if="estimate.disclaimer"
      >
        <h3 class="text-xl font-bold text-gray-900 mt-3">Disclaimer</h3>
        <p v-html="estimate.disclaimer"></p>
      </div>
      <button
        class="button inline-flex items-center px-4 py-3 mt-4 rounded text-white font-bold bg-blue-600 hover:bg-blue-500 transition duration-150 ease-in-out print:hidden"
        @click="newArrangement"
      >
        Start New Arrangment
      </button>
      <div :class="$style.estimateContact">
        <p class="font-bold text-lg">{{ estimate.business.name }}</p>
        <p class="font-medium">{{ streetAddress }}</p>
        <p class="font-medium">{{ location }}</p>
        <a
          :href="'tel:' + estimate.business.phone"
          class="text-blue-600 font-medium"
        >
          <p>{{ estimate.business.phone }}</p>
        </a>
        <a
          :href="estimate.business.website"
          class="text-blue-600 font-medium"
          target="_blank"
        >
          {{ estimate.business.website }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as Sentry from "@sentry/vue";
import axios from "axios";
import EventBus from "../utils/EventBus";
import LoadingMessage from "../components/LoadingMessage";

export default {
  name: "Estimate",
  components: { LoadingMessage },
  data() {
    return {
      loading: true,
      estimate: null,
    };
  },
  computed: {
    ...mapGetters(["id", "subdomain", "apiBaseUrl"]),
    streetAddress() {
      return `${this.estimate.business.address.line_1}${
        this.estimate.business.address.line_2
          ? ", " + this.estimate.business.address.line_2
          : ""
      }`;
    },
    location() {
      return `${this.estimate.business.address.city}, ${this.estimate.business.address.state} ${this.estimate.business.address.zip}`;
    },
  },
  created() {
    const scope = new Sentry.Scope();
    scope.setTags({
      tags: {
        id: this.id,
        subdomain: this.subdomain,
        estimate: this.$route.params.id,
      },
    });

    if (this.$route.params.id) {
      axios
        .get(`${this.apiBaseUrl}/${this.id}/estimate/${this.$route.params.id}`)
        .then((res) => {
          this.estimate = res.data.data;
          EventBus.$emit(
            "ViewEstimate",
            this.$route,
            this.estimate.estimated_total.cents / 100
          );
          this.loading = false;
        })
        .catch((err) => {
          Sentry.captureException(err, scope);
          EventBus.$emit("Error", `Error on ${this.subdomain}: ${err.message}`);
          this.$router.push("/404-not-found");
        });
    } else {
      this.loading = false;
      EventBus.$emit(
        "Error",
        `Error on ${this.subdomain}: Estimate ${this.$route.params.id} not found`
      );
      this.$router.push("/404-not-found");
    }
  },
  methods: {
    newArrangement() {
      this.$store.dispatch("RESET_SELECTIONS");
      EventBus.$emit("NewEstimate", this.$route);
      this.$router.push("/");
    },
  },
};
</script>

<style module>
.printButton {
  @apply border-none bg-transparent text-lg text-blue-600 cursor-pointer print:hidden;
}

.estimateContact {
  @apply text-center text-sm mt-6;
}

.estimateContact > p,
a > p {
  margin: 0;
}

.hr {
  height: 1px;
  box-sizing: border-box;
  border-color: rgb(229, 231, 235);
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-style: solid;
}
</style>
