function uuid() {
  var seed = Date.now();
  if (window.performance && typeof window.performance.now === "function") {
    seed += performance.now();
  }

  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      var r = (seed + Math.random() * 16) % 16 | 0;
      seed = Math.floor(seed / 16);

      return (c === "x" ? r : r & (0x3 | 0x8)).toString(16);
    }
  );

  return uuid;
}

export default {
  state: {
    started: null,
    products: [],
    needType: null,
    disposition: null,
    leadSubmitted: false,
    sessions: [],
    completed: false,
    leadId: null,
    modalSkipped: false,
  },
  getters: {
    needType: (state) => state.needType,
    disposition: (state) => state.disposition,
    leadCaptured: (state) => state.leadSubmitted,
    selections: (state) => state.products,
    started: (state) => state.started,
    currentSession: (state) => {
      return state.sessions.length > 1
        ? state.sessions[state.sessions.length - 1]
        : state.sessions[0];
    },
    sessions: (state) => state.sessions,
    completed: (state) => state.completed,
    leadId: (state) => state.leadId,
    modalSkipped: (state) => state.modalSkipped,
  },
  mutations: {
    setStarted: (state) => (state.started = new Date().toISOString()),
    addSession: (state) => state.sessions.push(uuid()),
    setCompleted: (state) => (state.completed = true),
    setSelection: (state, payload) => {
      let duplicateSelection = state.products.find(
        (sel) => sel.id === payload.id
      );
      if (!duplicateSelection) {
        state.products.push(payload);
      }
    },
    setNeedType: (state, payload) => (state.needType = payload),
    setDisposition: (state, payload) => (state.disposition = payload),
    setLeadSubmitted: (state) => (state.leadSubmitted = true),
    setLeadId: (state, payload) => (state.leadId = payload),
    setModalSkipped: (state) => (state.modalSkipped = true),
    resetStarted: (state) => (state.started = null),
    resetCompleted: (state) => (state.completed = false),
    resetProductSelections: (state) => (state.products = []),
    resetNeedType: (state) => (state.needType = null),
    resetDisposition: (state) => (state.disposition = null),
  },
  actions: {
    START: ({ commit }) => {
      commit("setStarted");
    },
    RESET_SELECTIONS: ({ commit }) => {
      commit("resetStarted");
      commit("resetCompleted");
      commit("resetNeedType");
      commit("resetDisposition");
      commit("resetProductSelections");
    },
  },
};
