<template>
  <div class="mx-auto">
    <div class="flex flex-col mt-0">
      <p class="font-bold text-lg text-gray-600">
        <slot name="header"></slot>
      </p>
      <div class="grid grid-cols-4 gap-4 w-full">
        <div class="form-group col-span-2">
          <label for="lead-fname"
            >First Name <span class="font-bold text-red-600">*</span></label
          >
          <input
            class="form-input"
            type="text"
            placeholder="Enter First Name"
            v-model="fname"
            id="lead-fname"
            required
          />
        </div>
        <div class="form-group col-span-2">
          <label for="lead-lname"
            >Last Name <span class="font-bold text-red-600">*</span></label
          >
          <input
            class="form-input"
            type="text"
            placeholder="Enter Last Name"
            v-model="lname"
            id="lead-lname"
            required
          />
        </div>
      </div>
      <div class="form-group w-full my-2">
        <label for="lead-email"
          >Email Address <span class="font-bold text-red-600">*</span></label
        >
        <input
          class="form-input"
          type="email"
          placeholder="Enter Email Address"
          v-model="email"
          id="lead-email"
          required
        />
      </div>
      <div class="form-group w-full my-2">
        <label for="lead-zip"
          >Zip Code <span class="font-bold text-red-600">*</span></label
        >
        <input
          class="form-input"
          type="text"
          placeholder="Enter Zip Code"
          v-model="zip"
          id="lead-zip"
          required
        />
      </div>
      <div class="form-group w-full my-2">
        <label for="lead-phone"
          >Phone Number <em class="text-sm text-gray-500">(Optional)</em></label
        >
        <input
          class="form-input"
          type="text"
          placeholder="Enter Phone Number"
          v-model="phone"
          v-on:keyup="phone ? (phone = formatPhone(phone)) : phone"
          id="lead-phone"
          required
        />
      </div>
      <div v-show="showError" class="text-red-500 mt-2" role="alert">
        {{ errorMessage }}
      </div>
    </div>
    <div class="grid grid-cols-4 gap-4 mt-0">
      <button
        class="view-estimate-button button col-span-2"
        :class="{ 'col-span-4': !skippable }"
        :style="{ backgroundColor: primaryColor }"
        @click="reviewSubmit()"
        title="Click to view your selections"
        :disabled="loading"
      >
        <LoadingIndicator v-if="loading" />
        <slot name="btn-text"></slot>
      </button>
      <button
        class="col-span-2 skip-this-button button"
        @click="skip()"
        v-if="skippable"
      >
        Skip
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import * as Sentry from "@sentry/vue";
import { mapGetters } from "vuex";
import EventBus from "../utils/EventBus";
import LoadingIndicator from "../components/LoadingIndicator";

export default {
  name: "LeadForm",
  props: ["skippable"],
  components: { LoadingIndicator },
  data() {
    return {
      fname: null,
      lname: null,
      email: null,
      zip: null,
      phone: null,
      showError: false,
      errorMessage: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters([
      "id",
      "apiBaseUrl",
      "isPreview",
      "needType",
      "disposition",
      "selections",
      "primaryColor",
      "started",
      "currentSession",
      "completed",
      "embedded",
      "queryString",
      "version",
    ]),
    validateEmail() {
      const expression = /\S+@\S+/;
      return expression.test(String(this.email).toLowerCase());
    },
    validateZip() {
      return this.zip.length === 5;
    },
    validatePhone() {
      if (!this.phone) {
        return false;
      }
      return this.phone.length >= 10;
    },
    estimatedTotal() {
      // Final estimated total is calculated server-side.
      // this estimatedTotal computed is for the analytics only.
      let total = 0;
      this.selections.forEach((sel) => {
        total += sel.selections[0].price.cents;
      });
      return total / 100;
    },
    lead() {
      return {
        session: this.currentSession,
        version: this.version,
        fname: this.fname,
        lname: this.lname,
        email: this.email,
        phone: this.phone,
        zipcode: this.zip,
        disposition: this.disposition,
        need_type: this.needType,
        selections: this.selections,
        preview: this.isPreview,
        embedded: this.embedded,
        started_at: this.started,
        query_string: this.queryString,
        completed: this.completed,
        is_cta: this.skippable,
      };
    },
  },
  mounted() {
    if (this.$route.path === "/review") {
      EventBus.$emit("Review", this.selections, this.estimatedTotal);
      this.$store.commit("setCompleted");
    }
  },
  methods: {
    /**
     * Called on keyup when the phone number input is focused
     * This will format the number as the user is typing
     */
    formatPhone(phoneNumber) {
      let formattedNumber;
      const input = phoneNumber.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
      const zip = input.substring(0, 3);
      const middle = input.substring(3, 6);
      const last = input.substring(6, 10);

      if (input.length > 6) {
        formattedNumber = `(${zip}) ${middle} - ${last}`;
      } else if (input.length > 3) {
        formattedNumber = `(${zip}) ${middle}`;
      } else if (input.length > 0) {
        formattedNumber = `(${zip}`;
      }
      return formattedNumber;
    },
    submit() {
      const scope = new Sentry.Scope();

      this.loading = true;
      axios
        .post(`${this.apiBaseUrl}/${this.id}/leads`, this.lead)
        .then((response) => {
          const data = response.data.data;

          if (response.status === 201) {
            if (this.skippable) {
              // Only called when captured in the LeadModal
              this.$emit("captured");
              setTimeout(() => {
                this.$store.commit("setLeadSubmitted");
                this.$store.commit("setLeadId", data.lead);
              }, 3000);
            } else {
              // Form is on "/review"
              this.$store.commit("setLeadSubmitted");
              this.$store.commit("setLeadId", data.lead);
            }

            EventBus.$emit("NewLead", this.estimatedTotal);

            setTimeout(() => {
              if (this.completed) {
                this.$router.push(`estimate/${data.session}`);
              } else {
                this.$emit("close");
              }
              this.loading = false;
            }, 50);
          } else {
            scope.setExtras({
              data: response.data,
              headers: response.headers,
              statusCode: response.status,
            });
          }
        })
        .catch((error) => {
          scope.setTags("page", this.$route.name);
          scope.setTags("subdomain", this.subdomain);

          const sentryID = Sentry.captureException(error, scope);

          EventBus.$emit(
            "Error",
            `Error on ${this.subdomain}: ${error.message}`,
            true,
            sentryID
          );

          this.showError = true;
          this.errorMessage = error.message;
          this.errorTimer();
        });
    },
    errorTimer() {
      // Timer to hide the error message
      setTimeout(() => {
        this.showError = false;
        this.errorMessage = null;
      }, 5000);
    },
    setError(input) {
      switch (input) {
        case "invalidPhone":
          this.showError = true;
          this.errorMessage = "Please enter a valid phone number";
          this.errorTimer();
          break;
        case "invalidEmail":
          this.showError = true;
          this.errorMessage = "Please enter a valid email address";
          this.errorTimer();
          break;
        case "invalidZip":
          this.showError = true;
          this.errorMessage = "Please enter a valid zip code";
          this.errorTimer();
          break;
        default:
          this.showError = true;
          this.errorMessage = "Please finish filling in the required fields";
          this.errorTimer();
          break;
      }
    },
    reviewSubmit() {
      /**
       *  Checks that the user has filled out the form correctly and throws respective errors if not
       */
      if (!this.fname || !this.lname) {
        this.setError("generic");
      } else if (!this.validatePhone && this.phone !== null) {
        this.setError("invalidPhone");
      } else if (!this.validateEmail) {
        this.setError("invalidEmail");
      } else if (!this.validateZip) {
        this.setError("invalidZip");
      } else {
        this.submit();
      }
    },
    skip() {
      this.$emit("close");
      this.$store.commit("setModalSkipped");
    },
  },
};
</script>

<style scoped>
#fe-app-wrapper #main-wrapper .form-input {
  @apply w-full block py-3 px-3 rounded border border-gray-300 shadow-none outline-none focus:border-blue-500 mt-1;
  box-sizing: border-box;
  border-style: solid;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
}

#fe-app-wrapper #main-wrapper .form-input {
  padding: 0.75rem 1rem !important;
  color: #333 !important;
}

#fe-app-wrapper #main-wrapper .formInput::placeholder {
  color: #2c3e50 !important;
}

#fe-app-wrapper #main-wrapper .form-group > label {
  font-weight: 400;
  color: #333 !important;
}

#fe-app-wrapper #main-wrapper .view-estimate-button {
  @apply w-full mt-4 rounded font-bold px-8 py-4 text-white text-center bg-blue-600 hover:bg-blue-500 flex justify-center items-center;
  font-size: 100%;
}

#fe-app-wrapper #main-wrapper .skip-this-button {
  @apply w-full mt-4 rounded font-bold px-8 py-4 text-white text-center bg-gray-600 hover:bg-gray-500 flex justify-center items-center;
  font-size: 100%;
}
</style>
