/**
 * Called when the user starts a new estimate from `/estimate/$ID`
 *
 * @param {Object} route - The current this.$route object
 * @returns
 */
export default function (route) {
  if (!route) {
    console.error("FE Analytics: NewEstimate - Route object is required.");
    return;
  }

  let fb = [
    "trackSingleCustom",
    "NewEstimate",
    {
      event_category: "engagement",
      event_label: route.path,
    },
  ];

  if (this.shouldLog) {
    console.debug("--- Facebook Pixel (Not including Pixel ID) ---");
    console.debug(fb);
    console.debug("----");
  }

  if (this.sendToFb && fb.length > 0) {
    window.fbq(fb[0], this.pixel, fb.slice(1));
  }

  if (this.shouldHoard) {
    window.hoard.send("new_estimate", this.session, {
      l: route.params.id,
    });
  }
}
