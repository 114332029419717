<template>
  <div id="fe-app-not-found">
    <h1>404 Not Found</h1>
    <p>
      Whoops! Something went wrong. Click below to start building an
      arrangement.
    </p>
    <router-link to="/">Start Arrangement</router-link>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped>
#fe-app-wrapper #main-wrapper #fe-app-not-found {
  @apply text-center mt-8 md:mt-4;
}

#fe-app-wrapper #main-wrapper #fe-app-not-found h1 {
  font-size: 2rem !important;
  font-weight: 800;
  margin-bottom: 1rem;
}

#fe-app-wrapper #main-wrapper #fe-app-not-found p {
  margin-bottom: 2rem;
  font-size: 1.25rem !important;
}

#fe-app-wrapper #main-wrapper #fe-app-not-found a {
  display: block;
  width: 100%;
  border-radius: 3px;
  font-weight: 800;
  color: #fff;
  text-decoration: none;
  padding: 1rem 0rem;
  background-color: rgb(37, 99, 235);
}

#fe-app-wrapper #main-wrapper #fe-app-not-found a:hover {
  background-color: rgb(59, 130, 246);
}
</style>
