/**
 * Vuex module to handle the fetching of the estimator schema, loading state, and
 * the checking and pulling of query parameters from the URL. e.g embedded, preview, etc.
 */
import axios from "axios";

import * as Sentry from "@sentry/vue";
import router from "../../router";
import EventBus from "../../utils/EventBus";

const scope = new Sentry.Scope();

function getURL(getters) {
  let base = `${getters.apiBaseUrl}/${getters.id}/start`;
  let params = [];

  if (getters.preview) {
    params.push("preview");
  }
  if (getters.version) {
    params.push("version");
  }

  if (params.length > 0) {
    for (let i = 0; i < params.length; i++) {
      if (i === 0) {
        base += `?${params[i]}=${getters[params[i]]}`;
      } else {
        base += `&${params[i]}=${getters[params[i]]}`;
      }
    }
  }
  return base;
}

export default {
  state: {
    loading: false,
    apiBaseUrl: null,
    id: null,
    subdomain: null,
    business: null,
    preview: false,
    embedded: false,
    queryString: null,
    primaryColor: null,
    index: null,
    description: null,
    atNeedMessage: null,
    version: null,
    analytics: {
      fb: false,
    },
  },
  getters: {
    loading: (state) => state.loading,
    apiBaseUrl: (state) => state.apiBaseUrl,
    business: (state) => state.business,
    description: (state) => state.description,
    resources: (state) => state.resources ?? [],
    disclaimer: (state) => state.disclaimer ?? "",
    id: (state) => state.id,
    subdomain: (state) => state.subdomain,
    embedded: (state) => state.embedded,
    isPreview: (state) => state.preview,
    atNeedMessage: (state) => state.atNeedMessage,
    index: (state, rootState) => {
      if (rootState.disposition) {
        return state.index[`${rootState.disposition}`];
      }
    },
    primaryColor: (state) => state.primaryColor,
    queryString: (state) => state.queryString,
    analytics: (state) => state.analytics,
    version: (state) => state.version,
  },
  mutations: {
    setLoading: (state, payload) => {
      state.loading = payload;
    },
    setApiBaseUrl: (state, payload) => {
      state.apiBaseUrl = payload;
    },
    initFacebookPixel: (state) => {
      // initialize the fbq with the pixel returned from schema
      if (state.business.facebook.pixel_id) {
        // Facebook Pixel
        !(function (f, b, e, v, n, t, s) {
          if (f.fbq) return;
          n = f.fbq = function () {
            n.callMethod
              ? n.callMethod.apply(n, arguments)
              : n.queue.push(arguments);
          };
          if (!f._fbq) f._fbq = n;
          n.push = n;
          n.loaded = !0;
          n.version = "2.0";
          n.queue = [];
          t = b.createElement(e);
          t.async = !0;
          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        })(
          window,
          document,
          "script",
          "https://connect.facebook.net/en_US/fbevents.js"
        );

        state.analytics.fb = true;
      }
    },
    setId: (state, payload) => {
      state.id = payload;
    },
    setSubdomain: (state, payload) => {
      state.subdomain = payload;
    },
    setPreview: (state, payload) => {
      state.preview = payload;
    },
    setEmbedded: (state, payload) => {
      state.embedded = payload;
    },
    setBusiness: (state, payload) => {
      state.business = payload;
    },
    setIndex: (state, payload) => {
      state.index = payload;
    },
    setDescription: (state, payload) => {
      state.description = payload;
    },
    setAtNeedMessage: (state, payload) => {
      state.atNeedMessage = payload;
    },
    setPrimaryColor: (state, payload) => {
      state.primaryColor = payload;
    },
    setQueryString: (state, payload) => {
      state.queryString = payload;
    },
    setVersion: (state, payload) => {
      state.version = payload;
    },
  },
  actions: {
    INIT: ({ commit, getters }, payload) => {
      commit("setLoading", true);
      commit("setApiBaseUrl", payload.apiBaseUrl);
      commit("setId", payload.id);

      // Set preview from script tag config/FuneralEstimator props
      if (payload.preview) {
        commit("setPreview", payload.preview);
      }

      if (payload.embedded) {
        commit("setEmbedded", payload.embedded);
      }

      if (payload.version) {
        commit("setVersion", payload.version);
      }

      commit("setQueryString", window.location.search);

      const url = getURL(getters);

      scope.setTags({
        tags: {
          id: payload.id,
          preview: getters.getPreview,
          embedded: getters.getEmbedded,
          queryString: getters.getQueryString,
          version: getters.version || "unknown",
          url: url,
        },
      });

      axios
        .get(url)
        .then(function (res) {
          const data = res.data.data;

          commit("setSubdomain", data.subdomain);

          scope.setTag("subdomain", data.subdomain);

          commit("setBusiness", data.business);
          commit("setIndex", data.index);
          commit("setDescription", data.description);
          commit("setAtNeedMessage", data.at_need_message);

          // Only set preview from the response if the config/props is false
          if (!getters.getPreview && data.preview) {
            commit("setPreview", data.preview);
          }

          // If version has not already been set from the config/props,
          // set it from the response
          //
          // There are two possible scenarios:
          // 1: no data-version
          // 2: has data-version=456
          // This handles #1
          if (!getters.version) {
            commit("setVersion", data.version);
            scope.setTag("version", data.version);
          }

          if (!getters.primaryColor) {
            commit("setPrimaryColor", data.primary_color);
          }

          commit("initFacebookPixel");
          EventBus.$emit(
            "Init",
            getters.preview,
            getters.analytics.fb,
            getters.currentSession
          );
          commit("setLoading", false);
        })
        .catch(function (err) {
          if (err.response) {
            EventBus.$emit(
              "Error",
              "API Request was made, but the server returned a non-200 status code"
            );
          } else if (err.request) {
            EventBus.$emit(
              "Error",
              "API request was made but no response was received"
            );
          }

          scope.setExtra("error_response", err.toJSON());

          const sentryID = Sentry.captureException(err, scope);

          EventBus.$emit(
            "Error",
            `[ ERROR ] Message: ${err.message} SentryID: ${sentryID}`,
            true,
            sentryID
          );
          router.push("/500-internal-server-error");
        });
    },
  },
};
