<template>
  <div class="lg:w-1/2 mx-auto">
    <LoadingMessage v-if="loading && leadCaptured && leadId">
      One moment while we put together your arrangement
    </LoadingMessage>
    <LeadForm :skippable="false" v-if="!leadCaptured || !leadId">
      <template v-slot:header
        >Fill out the form below to view your estimated arrangement.</template
      >
      <template v-slot:btn-text>Get My FREE Estimate</template>
    </LeadForm>
  </div>
</template>

<script>
import axios from "axios";
import * as Sentry from "@sentry/vue";
import { mapGetters } from "vuex";
import EventBus from "../utils/EventBus";
import LeadForm from "../components/LeadForm.vue";
import LoadingMessage from "../components/LoadingMessage.vue";

export default {
  name: "Review",
  components: { LeadForm, LoadingMessage },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters([
      "id",
      "apiBaseUrl",
      "subdomain",
      "started",
      "needType",
      "disposition",
      "selections",
      "leadCaptured",
      "completed",
      "leadId",
      "sessions",
      "currentSession",
      "version",
    ]),
    estimatedTotal() {
      // Final estimated total is calculated server-side.
      // this estimatedTotal computed is for the analytics only.
      let total = 0;
      this.selections.forEach((sel) => {
        total += sel.selections[0].price.cents;
      });
      return total / 100;
    },
  },
  beforeMount() {
    this.$store.commit("setCompleted");
  },
  mounted() {
    EventBus.$emit("Review", this.selections, this.estimatedTotal);
    // If the user has submitted their info before /review and it's the first session
    if (this.leadCaptured && this.leadId && this.sessions.length === 1) {
      this.updateLead();
    } else if (this.leadCaptured && this.leadId && this.sessions.length >= 2) {
      this.submitNewSession();
    }
  },
  methods: {
    updateLead() {
      const scope = new Sentry.Scope();

      this.loading = true;
      axios
        .put(
          `${this.apiBaseUrl}/${this.id}/leads/${this.leadId}/sessions/${this.currentSession}`,
          {
            selections: this.selections,
            completed: this.completed,
            version: this.version,
          }
        )
        .then((response) => {
          const data = response.data.data;

          if (response.status === 200) {
            this.$router.push(`estimate/${data.session}`);
          } else {
            scope.setExtras({
              data: response.data,
              headers: response.headers,
              statusCode: response.status,
            });
          }
        })
        .catch((error) => {
          scope.setTags("page", "review");
          scope.setTags("subdomain", this.subdomain);
          scope.setTag("version", this.version);

          const sentryID = Sentry.captureException(error, scope);

          EventBus.$emit(
            "Error",
            `Error on ${this.subdomain}: ${error.message}`,
            true,
            sentryID
          );
          this.redirect(error.response.status);
        });
    },
    submitNewSession() {
      const scope = new Sentry.Scope();

      this.loading = true;
      axios
        .post(`${this.apiBaseUrl}/${this.id}/leads/${this.leadId}/sessions`, {
          session: this.currentSession,
          version: this.version,
          started_at: this.started,
          need_type: this.needType,
          disposition: this.disposition,
          selections: this.selections,
        })
        .then((response) => {
          const data = response.data.data;

          if (response.status === 200) {
            this.$router.push(`estimate/${data.session}`);
          } else {
            scope.setExtras({
              data: response.data,
              headers: response.headers,
              statusCode: response.status,
            });
          }
        })
        .catch((error) => {
          scope.setTags("page", "review");
          scope.setTags("subdomain", this.subdomain);

          const sentryID = Sentry.captureException(error, scope);

          EventBus.$emit(
            "Error",
            `Error on ${this.subdomain}: ${error.message}`,
            true,
            sentryID
          );
          this.redirect(error.response.status);
        });
    },
    redirect(statusCode) {
      if (statusCode >= 500) {
        this.$router.push("/500-internal-server-error");
      } else {
        this.$router.push("/404-not-found");
      }
    },
  },
};
</script>
