import Vue from "vue";
import Vuex from "vuex";
import selections from "./modules/selections";
import setup from "./modules/setup";

Vue.use(Vuex);

let store = new Vuex.Store({
  modules: {
    selections,
    setup,
  },
});

export default store;
