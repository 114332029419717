<template>
  <div id="loading-message">
    <h1>
      <slot></slot>
    </h1>
    <div class="lds-ring mt-10">
      <div :style="styles"></div>
      <div :style="styles"></div>
      <div :style="styles"></div>
      <div :style="styles"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LoadingMesssage",
  computed: {
    ...mapGetters(["primaryColor"]),
    styles() {
      if (this.primaryColor) {
        return `border: 8px solid ${this.primaryColor} !important; border-color: ${this.primaryColor} transparent transparent transparent !important;`;
      }
      return `border: 8px solid #3182ce !important; border-color: #3182ce transparent transparent transparent !important;`;
    },
  },
};
</script>

<style scoped>
#fe-app-wrapper #loading-message {
  padding: 4rem;
  text-align: center;
}
#fe-app-wrapper #loading-message h1 {
  line-height: 1;
  font-size: 1.875rem !important;
  font-weight: 800;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
