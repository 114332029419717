var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('LoadingMessage',[_vm._v(" Please wait while we prepare the next step for your arrangement ")]):_c('div',[(
      _vm.selections.length >= 2 &&
      _vm.needType !== _vm.$const.NEED_TYPE_AT_NEED &&
      !_vm.leadCaptured &&
      !_vm.modalSkipped
    )?_c('div',[_c('Alert',[_c('div',{staticClass:"flex flex-col lg:flex-row lg:justify-between items-center"},[_c('span',[_vm._v(" Don't lose your arrangement! ")]),_c('button',{staticClass:"button w-full lg:w-auto save-estimate-button mt-2 lg:mt-0 mb-2",on:{"click":function($event){return _vm.toggleModal()}}},[_vm._v(" Save Now ")])])]),_c('LeadModal',{directives:[{name:"show",rawName:"v-show",value:(_vm.modalShown),expression:"modalShown"}],on:{"close":function($event){return _vm.toggleModal()}}})],1):_vm._e(),_c('PageHeader',{attrs:{"title":_vm.currentPage.title,"description":_vm.currentPage.description}}),_c('div',{staticClass:"grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4"},_vm._l((_vm.currentPage.products),function(product){return _c('div',{key:product.id,staticClass:"card",on:{"click":function($event){return _vm.selectProduct(product)}}},[(product.image)?_c('img',{staticClass:"card-image",attrs:{"src":product.image,"alt":product.name}}):_vm._e(),(product.icon)?_c('div',{staticClass:"w-1/2 mx-auto icons mt-2",style:(_vm.iconStyles),domProps:{"innerHTML":_vm._s(product.icon)}}):_vm._e(),_c('div',{staticClass:"flex flex-col md:justify-center items-center mb-2",class:{ 'md:flex-row md:justify-between': !_vm.isDefaultPage }},[(_vm.currentPage.id != 'NEED_TYPE')?_c('h3',{staticClass:"card-title"},[_vm._v(" "+_vm._s(product.name)+" ")]):_vm._e(),(
            product.show_price && product.price.formatted && !_vm.isDefaultPage
          )?_c('h3',{staticClass:"card-title"},[_vm._v(" "+_vm._s(product.price.formatted)+" ")]):_vm._e()]),(product.description)?_c('p',{staticClass:"leading-tight text-gray-700",class:{ 'text-center': _vm.isDefaultPage },domProps:{"innerHTML":_vm._s(product.description)}}):_vm._e()])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }