import Vue from "vue";
import FuneralEstimator from "./FuneralEstimator.vue";
import router from "./router";
import store from "./store";
import "@/assets/css/tailwind.css";
import { constants } from "./constants";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Vue.config.productionTip = false;
Vue.config.devtools = process.env.VUE_APP_NODE_ENV === "development";

Vue.prototype.$const = constants;

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN || null,
  integrations: [new Integrations.BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  // tracesSampleRate should be an env var and set to 0.2 in prod
  tracesSampleRate: process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE || 0.2,
  logErrors: process.env.VUE_APP_NODE_ENV === "development",
});

const currentScript =
  document.currentScript ||
  document.querySelector(
    'script[src^="https://cdn.pricemyfuneral.com/e/embed.js"]'
  );

// config Allows for setting config on the window (usually during development)
// Or using the `<script>` tag's `data-` attributes
let config = window.fe_config || {
  // Estimator UUID
  id: currentScript.dataset.id || null,

  embedded: "embedded" in currentScript.dataset === true ? true : false,

  preview: "preview" in currentScript.dataset === true ? true : false,

  // Custom container to place our app div within
  container: currentScript.dataset.container,

  // Custom API Base URL
  apiBaseUrl: currentScript.dataset["url"] || process.env.VUE_APP_API_BASE_URL,

  // Version of Estimator Schema
  version: currentScript.dataset["version"] || null,
};

if (config.apiBaseUrl === null) {
  console.error("  ERROR: API Base URL is not set.");
  Sentry.captureException(new Error("  ERROR: API Base URL is not set."));
}

window.fe_gtranslate_callback = function () {
  // eslint-disable-next-line
  new google.translate.TranslateElement(
    {
      pageLanguage: "en",
    },
    "google_translate_element"
  );
};

let container = document.querySelector(config.container);

if (!config.container || typeof container !== "object") {
  console.error(`  ERROR: ${config.container} does not exist on the DOM`);
  Sentry.captureException(
    new Error(`${config.container} does not exist on the DOM`)
  );
}

// Create a new div within the user-provided container so Vue can
// replace it with the FuneralEstimator component instead of replacing
// the provided container
let app = document.createElement("div");
app.id = "fe-estimator-app";
container.appendChild(app);

if (typeof config.id === undefined || !config.id) {
  Sentry.captureException(new Error("Estimator ID was not provided."));
  console.error("ERROR: No Estimator ID was provided.");
} else {
  // Hoard should be loaded before Vue.
  let hoardScript = document.createElement("script");
  hoardScript.type = "text/javascript";
  hoardScript.src =
    process.env.NODE_ENV === "development"
      ? "hoard.js"
      : "https://cdn.pricemyfuneral.com/h/hoard.js";
  hoardScript.async = true;
  hoardScript.setAttribute("data-id", config.id);
  if (process.env.NODE_ENV === "development") {
    hoardScript.setAttribute("data-endpoint", `${config.apiBaseUrl}/hoard`);
  }
  // document.head.appendChild() does not work in older browsers.
  document.getElementsByTagName("head")[0].appendChild(hoardScript);

  hoardScript.addEventListener("load", () => {
    new Vue({
      store,
      router,
      el: `${config.container} #fe-estimator-app`,
      // https://vuejs.org/v2/guide/render-function.html#The-Data-Object-In-Depth
      render: (el) =>
        el(FuneralEstimator, {
          props: {
            id: config.id,
            embedded: config.embedded,
            preview: config.preview,
            apiBaseUrl: config.apiBaseUrl,
            version: config.version,
          },
        }),
    });
  });
}
