<template>
  <LoadingMessage v-if="loading">
    Please wait while we prepare the next step for your arrangement
  </LoadingMessage>
  <div v-else>
    <div
      v-if="
        selections.length >= 2 &&
        needType !== $const.NEED_TYPE_AT_NEED &&
        !leadCaptured &&
        !modalSkipped
      "
    >
      <Alert>
        <div class="flex flex-col lg:flex-row lg:justify-between items-center">
          <span> Don't lose your arrangement! </span>
          <button
            class="button w-full lg:w-auto save-estimate-button mt-2 lg:mt-0 mb-2"
            @click="toggleModal()"
          >
            Save Now
          </button>
        </div>
      </Alert>
      <LeadModal v-show="modalShown" v-on:close="toggleModal()" />
    </div>
    <PageHeader
      :title="currentPage.title"
      :description="currentPage.description"
    />
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
      <div
        v-for="product in currentPage.products"
        :key="product.id"
        class="card"
        @click="selectProduct(product)"
      >
        <img
          v-if="product.image"
          :src="product.image"
          :alt="product.name"
          class="card-image"
        />
        <div
          class="w-1/2 mx-auto icons mt-2"
          :style="iconStyles"
          v-if="product.icon"
          v-html="product.icon"
        ></div>
        <div
          class="flex flex-col md:justify-center items-center mb-2"
          :class="{ 'md:flex-row md:justify-between': !isDefaultPage }"
        >
          <h3 class="card-title" v-if="currentPage.id != 'NEED_TYPE'">
            {{ product.name }}
          </h3>
          <h3
            class="card-title"
            v-if="
              product.show_price && product.price.formatted && !isDefaultPage
            "
          >
            {{ product.price.formatted }}
          </h3>
        </div>
        <p
          v-if="product.description"
          class="leading-tight text-gray-700"
          :class="{ 'text-center': isDefaultPage }"
          v-html="product.description"
        ></p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import * as Sentry from "@sentry/vue";
import EventBus from "../utils/EventBus";

import PageHeader from "../components/PageHeader";
import LoadingMessage from "../components/LoadingMessage.vue";
import Alert from "../components/Alert.vue";
import LeadModal from "../components/LeadModal.vue";

export default {
  components: { PageHeader, LoadingMessage, Alert, LeadModal },
  data() {
    return {
      loading: false,
      currentPage: null,
      modalShown: false,
    };
  },
  computed: {
    ...mapGetters([
      "id",
      "subdomain",
      "apiBaseUrl",
      "version",
      "index",
      "primaryColor",
      "selections",
      "needType",
      "leadCaptured",
      "modalSkipped",
    ]),
    isDefaultPage() {
      if (
        this.$route.params.id === "need" ||
        this.$route.params.id === "disposition"
      ) {
        return true;
      }
      return false;
    },
    iconStyles() {
      return {
        "--icon-styles": this.primaryColor ?? "rgba(37, 99, 235,1)",
      };
    },
  },
  created() {
    this.fetchPage();
  },
  watch: {
    $route: "fetchPage",
  },
  methods: {
    /**
     * Called when the user clicks on a product.
     * Handles storing the selection in vuex, sending analytics,
     * and confirming navigation.
     */
    selectProduct(product) {
      let page = this.currentPage;

      // Store the selection in Vuex depending on the type of page
      if (!this.isDefaultPage) {
        page.selections = [];
        page.selections.push(product);
        this.$store.commit("setSelection", page);
      } else {
        this.$store.commit(
          `set${page.id === "DISPOSITION" ? "Disposition" : "NeedType"}`,
          product.id
        );
      }

      // Send the product selection to analytics
      EventBus.$emit("SelectProduct", page, product);

      // Confirm navigation for rendering the next page
      if (this.isDefaultPage) {
        /**
         * If the product has a next key we use the value of it to route.
         * This is the case in need page products, but not disposition.
         * By leaving the next key off of the disposition product object we can
         * use the respective disposition index value provided by the vuex store.
         */
        this.$router.push(product.next ? product.next : `/page/${this.index}`);
      } else {
        this.$router.push(
          product.next != "review" ? `/page/${product.next}` : "/review"
        );
      }
    },
    fetchPage() {
      this.loading = true;
      const pageId = this.$route.params.id;
      const scope = new Sentry.Scope();
      scope.setTag("subdomain", this.subdomain);
      scope.setTag("page_id", pageId);

      if (pageId) {
        scope.setTag("has_route_param_id", true);
        if (this.isDefaultPage) {
          /**
           * Don't call API for a page object, instead use the value in $const
           * respective to the pageId. ('need' or 'disposition')
           */
          this.currentPage = this.$const[`${pageId.toUpperCase()}`];
          this.loading = false;
          EventBus.$emit("ViewPage", this.currentPage);
        } else {
          axios
            .get(
              `${this.apiBaseUrl}/${this.id}/pages/${pageId}?version=${this.version}`
            )
            .then((res) => {
              this.currentPage = res.data.data;
              this.loading = false;
              EventBus.$emit("ViewPage", this.currentPage);
            })
            .catch((err) => {
              const sentryID = Sentry.captureException(err, scope);
              EventBus.$emit(
                "Error",
                `Error on ${this.subdomain}: ${err.message}`,
                true,
                sentryID
              );
              this.$router.push("/404-not-found");
            });
        }
      } else {
        EventBus.$emit(
          "Error",
          `Error on ${this.subdomain}: Page ${this.$route.params.id} not found`
        );
        this.$router.push("/404-not-found");
      }
    },
    toggleModal() {
      this.modalShown = !this.modalShown;
    },
  },
};
</script>

<style scoped>
/** 
 * In order for content to be styled in v-html markup we have to use the 
 * deep selector ">>>" and pass the return value from the iconStyles computed.
 * https://shayneo.com/blog/binding-css-variables-with-vue/
 */
#fe-app-wrapper #main-wrapper .icons >>> svg {
  color: var(--icon-styles) !important;
}
#fe-app-wrapper #main-wrapper .card {
  @apply shadow-md rounded cursor-pointer p-4;
  border: 2px solid #e5e7eb;
}
#fe-app-wrapper #main-wrapper .card:hover {
  @apply bg-blue-100 border-blue-300;
}
#fe-app-wrapper #main-wrapper .card-title {
  @apply font-bold text-xl leading-5 my-2;
}
#fe-app-wrapper #main-wrapper .card-selected {
  @apply bg-blue-100 border-2 border-blue-300;
}
#fe-app-wrapper #main-wrapper .card-image {
  @apply block mb-2 w-1/2 mx-auto lg:w-full lg:mx-0;
}
#fe-app-wrapper #main-wrapper .save-estimate-button {
  @apply rounded font-bold p-2 text-white text-center bg-blue-600 hover:bg-blue-500;
  font-size: 100%;
}
</style>
