/**
 * Handles all analytics functionality of the internal "Start" event.
 *
 * @param {Object} route - The current this.$route object
 */

export default function (route) {
  if (!route) {
    console.error("FE Analytics: Start - A route object is required.");
    return;
  }

  let fb = [
    "trackSingleCustom",
    "StartEstimate",
    {
      event_category: "engagement",
      event_label: route.path,
      event_value: 0,
    },
  ];

  if (this.shouldLog) {
    console.debug("--- Facebook Pixel (Not including Pixel ID) ---");
    console.debug(fb);
    console.debug("----");
  }

  if (this.sendToFb && fb.length > 0) {
    window.fbq(fb[0], this.pixel, fb.slice(1));
  }

  if (this.shouldHoard) {
    window.hoard.send("start", this.session);
  }
}
