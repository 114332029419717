/**
 * Called once the user has submitted the form on `/review`
 *
 * @param {Number} estimatedTotal - Client side calculated estimated total of the arrangement.
 */
export default function (estimatedTotal) {
  if (!estimatedTotal) {
    console.error("FE Analytics: NewLead - The Estimated Total is required.");
    return;
  }
  // https://developers.facebook.com/docs/facebook-pixel/reference#standard-events
  let fb = [
    "trackSingleCustom",
    "Lead",
    {
      content_name: "Review",
      currency: "USD",
      value: estimatedTotal,
    },
  ];

  if (this.shouldLog) {
    console.debug("--- Facebook Pixel (Not including Pixel ID) ---");
    console.debug(fb);
    console.debug("----");
  }

  if (this.sendToFb && fb.length > 0) {
    window.fbq(fb[0], this.pixel, fb.slice(1));
  }

  if (this.shouldHoard) {
    window.hoard.send("generate_lead", this.session);
  }
}
