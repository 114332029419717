<template>
  <div id="fe-preview-banner">
    <span v-if="isPreview">Preview Mode</span>
  </div>
</template>

<script>
// Only rendered when the estimator is in preview mode
import { mapGetters } from "vuex";

export default {
  name: "PreviewBanner",
  computed: {
    ...mapGetters(["isPreview"]),
  },
};
</script>

<style scoped>
#fe-preview-banner {
  padding: 0.5rem;
  font-weight: 800;
  text-align: center;
  color: #fff;
  background-color: rgb(37, 99, 235);
}
</style>
