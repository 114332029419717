/**
 * Called anywhere that could throw an error such as an axios call to the API
 *
 * @param {String} description - The description of the exception that occurred (Default: "Unknown Exception")
 * @param {Boolean} fatal - Whether or not the exception was a fatal one (Default: true)
 * @param {String} sentryID - UUID returned from Sentry.captureException()
 */
export default function (
  description = "Unknown Exception",
  fatal = true,
  sentryID = ""
) {
  let fb = [
    "trackSingleCustom",
    "Exception",
    {
      description: description,
      fatal: fatal,
    },
  ];

  if (this.shouldLog) {
    console.debug("--- Facebook Pixel (Not including Pixel ID) ---");
    console.debug(fb);
    console.debug("----");
  }

  if (this.sendToFb && fb.length > 0) {
    window.fbq(fb[0], this.pixel, fb.slice(1));
  }

  if (this.shouldHoard) {
    window.hoard.send("error", this.session, {
      desc: description,
      f: fatal,
      sid: sentryID,
    });
  }
}
