<template>
  <LoadingMessage v-if="loading">
    One moment while we personalize your experience
  </LoadingMessage>
  <div v-else>
    <h1 id="index-title">{{ business.name }}</h1>
    <div id="fe-description-container">
      <div v-if="description">
        <div v-html="description"></div>
      </div>
      <div v-else>
        <div>
          <p>
            Welcome to our Funeral Cost Estimator. Should you have any questions
            please feel free to call us at
            <a :href="'tel:' + business.phone" class="text-link">
              {{ business.phone }}</a
            >
            anytime! Thank you for your interest. We are here for you and
            because of you!
          </p>
        </div>
      </div>

      <button
        id="index-get-started-btn"
        :style="{ backgroundColor: primaryColor }"
        @click="start()"
      >
        Get Started
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingMessage from "../components/LoadingMessage";
import EventBus from "../utils/EventBus";

export default {
  name: "Index",
  components: { LoadingMessage },
  computed: {
    ...mapGetters(["loading", "business", "description", "primaryColor"]),
  },
  beforeMount() {
    this.$store.commit("addSession");
  },
  mounted() {
    // this is a hack for the time being
    // what's happening is that this event is getting emitted
    // BEFORE the session is committed to the store. @matt?
    setTimeout(function () {
      EventBus.$emit("ViewPage", { id: "Index" });
    }, 800);
  },
  methods: {
    start() {
      EventBus.$emit("Start", this.$route);
      this.$store.dispatch("START");
      this.$router.push("/page/need");
    },
  },
};
</script>

<style scoped>
#fe-app-wrapper #index-title {
  font-weight: 800;
  font-size: 1.875rem !important;
  color: rgb(51, 51, 51);
}

#fe-app-wrapper #main-wrapper #fe-description-container {
  color: rgb(51, 51, 51);
}

#fe-app-wrapper #index-get-started-btn {
  margin-top: 1rem;
  border-radius: 3px;
  width: 100%;
  display: block;
  cursor: pointer;
  border: transparent;
  padding: 1rem 0rem;
  font-weight: 800;
  color: #fff;
  text-align: center;
  background-color: rgb(37, 99, 235);
}

#fe-app-wrapper #index-get-started-btn:hover {
  background-color: rgb(59, 130, 246);
}

#fe-app-wrapper #fe-description-container div {
  @apply mt-4 text-xl;
}

#fe-app-wrapper #fe-description-container a.text-link {
  @apply text-blue-600 text-2xl hover:underline;
}
</style>
