/**
 * Called whenever a user selects a product for their arrangement
 *
 * @param {Object} page - The currentPage from whichever component is calling SelectProduct
 * @param {Object} product - The product selection from the user
 */
export default function (page, product) {
  if (!page || !product) {
    console.error(
      "FE Analytics: SelectProduct - A page or product object is required."
    );
    return;
  }

  let price = product.price === 0 ? 0 : product.price.cents / 100;

  // https://developers.facebook.com/docs/facebook-pixel/reference#standard-events
  let fb = [
    "trackSingle",
    "AddToCart",
    {
      content_ids: [product.id],
      content_name: product.name,
      content_type: "product",
      value: price,
      currency: "USD",
    },
  ];

  if (this.shouldLog) {
    console.debug("--- Facebook Pixel (Not including Pixel ID) ---");
    console.debug(fb);
    console.debug("----");
  }

  if (this.sendToFb && fb.length > 0) {
    window.fbq(fb[0], this.pixel, fb.slice(1));
  }

  if (this.shouldHoard) {
    window.hoard.send("select_product", this.session, {
      pg: page.id,
      prd: [
        {
          id: product.id,
          lp: page.products.findIndex((p) => p.id === product.id) + 1,
        },
      ],
    });
  }
}
